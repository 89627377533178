@tailwind base;
@tailwind components;
@tailwind utilities;




body{
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  font-family: 'Lato', sans-serif;
  font-family: 'Montserrat Alternates', sans-serif;
  font-family: 'Poppins', sans-serif;
  
}

.home__img{
    position: relative;
    background-image: url("https://andamanmed.com/wp-content/uploads/2023/02/Shutterstock_2137304159-1024x485.png");
    background-size: cover;
    background-position: center;
    height: fit-content; 
}

.home__img::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
}

.btn__touch{
  transition: ease-in-out 300ms;
}

.btn__touch:hover{
  background-color: #0f1829;
  border: solid 0.6px whitesmoke;
}



.numbers{
  position: relative;
  background-image: url("https://media.istockphoto.com/id/1194430803/photo/smart-female-it-programer-working-on-desktop-computer-in-data-center-system-control-room-team.jpg?s=612x612&w=0&k=20&c=ZqM_E-kjQaAtAV04I1LjW2jZo-q2YBVeT_NKora-1sM=");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.numbers::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}

.Shadow__x{
  box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
    border-bottom: #0779e4 solid 2px;
}

.shadow__box{
  border-bottom: 1px solid #007FFF;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(6, 141, 232, 0.5);    ;
}


.border__b{
  border: #1b9bf0 solid 1px;
  background: rgba(0, 127, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
}


.Button__{
  border: #007FFF solid 1px;
  background: rgba(0, 127, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
  padding: 9px;
  margin-top: 10px;
  color: white;
  transition: 300ms ease-in-out;
}

.serv__boreder{
  box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
  border-radius: 10px;
}

.avater__1{
  border: solid 2px rgba(0, 127, 255, 1);
}

.serv__box{
  box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
  border-bottom: #0779e4 solid 2px;
}


.hero {
  position: relative;
  background-image: url('https://www.ust.com/content/dam/ust/images/people/team-in-control-center-people.jpeg?auto=webp,smallest&quality=80');
  background-size: cover;
  background-position: center;
  height: 300px; /* You can adjust the height as needed */
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1; /* Make sure the text and button are above the pseudo-element */
}

.hero-content h2 {
  font-size: 2rem;
  font-weight: bold;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.contact-btn{
  background-color: #0779e4;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}
.contact-btn1{
  background-color: #0779e4;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.btn__touch1{
  transition: ease-in-out 300ms;
}

.btn__touch1:hover{
  background-color: #007FFF;
  border: solid 0.6px whitesmoke;
}
.img__border{
  border: solid #007FFF ;
}

.shadow__abt{
  border-bottom: 1px solid #007FFF;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(6, 141, 232, 0.5);
  padding: 10px;

}

.About{
  position: relative;
  background-image: url("https://images.pexels.com/photos/3688761/pexels-photo-3688761.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6);
}


.About::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}



.Contact{
  position: relative;
  background-image: url("https://img.freepik.com/free-photo/close-up-person-working-call-center_23-2149288176.jpg?t=st=1715738541~exp=1715742141~hmac=89930d1061058d7aa809d303bb3b5cbe697615c4e29889c512cfe649e41b8b67&w=1480");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.Contact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}